<style lang="scss">
#editmitarbeiter {
  .invis {
    align-self: center;
    margin-top: 25px;
  }

  h3 {
    margin-top: 100px;
    font-family: 'HelveticaNeueLTPro-Bd';
  }
  .headline-h2 {
    span {
      display: inline-block;
    }
  }
  .form-all {
    margin: 15px 0;
    .md6,
    .md12 {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      .w-select__label,
      .w-input__label {
        margin-bottom: 3px;
        letter-spacing: 1px;
        display: block;
        width: 100%;
        text-transform: uppercase;
      }
    }

    .w-input__input-wrap,
    .w-select__selection-wrap {
      display: block;
      border-radius: 0;
      height: auto;
      border: 0;
      input {
        border: 1px solid #ccc;
        display: block;
        width: 100%;
        color: #333;
        padding: 15px;
        &:focus {
          border: 1px solid #000;
        }
      }
    }

    .w-select__selection-wrap {
      .w-icon {
        top: 13px;
      }
    }
    .btn-box {
      margin-top: 30px;
    }
    .btn-form {
      height: auto;
      margin-right: 10px;
    }
  }
  .textarea-box {
    label {
      background: #fff;
      position: relative;
      left: 11px;
      top: 7px;
      padding: 0 4px;
    }
    textarea {
      border: 1px solid #ccc;
      display: block;
      width: 100%;
      color: #333;
      padding: 15px;
      border-radius: 0;
      width: 100%;
      height: 150px;
      &:focus {
        border: 1px solid #000;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .bild-box-outer {
    input {
      padding: 10px;
      margin-bottom: 30px;
    }
    .avatar {
      display: inline-block;
      max-width: 400px;
      padding: 10px;
      border: 1px solid #eee;
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
  }
}
</style>

<template>
  <!-- {{ person }} -->
  <div wrap class="row form-all">
    <div class="col-4">
      <div class="form-element">
        <label for="namefirst" :class="{ hasvalue: person.nameFirst != '' }">Vorname</label>
        <input class="form-control" type="text" id="namefirst" v-model="person.nameFirst" placeholder="Vorname" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="namelast" :class="{ hasvalue: person.nameLast != '' }">Nachname</label>
        <input class="form-control" type="text" id="namelast" v-model="person.nameLast" placeholder="Nachname" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="role" class="hasvalue">Rolle</label>
        <select class="form-select" v-model="person.role" id="role" placeholder="Rolle">
          <option :value="rol.value" v-for="rol in roles">{{ rol.label }}</option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="loginname" :class="{ hasvalue: person.login != '' }">Login</label>
        <input class="form-control" type="text" id="loginname" v-model="person.login" placeholder="Login" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="email" :class="{ hasvalue: person.email != '' }">Email</label>
        <input class="form-control" type="text" id="email" v-model="person.email" placeholder="Email" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="role" class="hasvalue">Standort</label>
        <select class="form-select" v-model="person.location">
          <option :selected="person.location == loc.label" v-for="loc in locations">{{ loc.label }}</option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="role" class="hasvalue">Team</label>
        <select class="form-select" v-model="person.team">
          <option :selected="person.team == teem.label" v-for="teem in teams">{{ teem.label }}</option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-element">
        <label for="position" :class="{ hasvalue: person.position != '' }">Position</label>
        <input class="form-control" type="text" id="position" v-model="person.position" placeholder="Position" />
      </div>
    </div>
    <div class="col-4 invis">
      <input class="form-check-input" type="checkbox" v-model="person.invisible" id="flexCheckChecked" />
      <label class="form-check-label" for="flexCheckChecked">
        Von Berechungen und Listen ausschliessen
      </label>
    </div>
    <div class="col-8 invis textarea-box">
      <label for="role" :class="{ hasvalue: person.absprachen !== '' }">Mitarbeiter Absprachen</label>
      <textarea class="form-control textarea-style" id="absprachen" v-model="person.absprachen"></textarea>
    </div>
  </div>

  <h3>Passwort</h3>
  <div class="row form-all">
    <div class="col-4">
      <div class="form-element">
        <label for="password" :class="{ hasvalue: person.password != '' }">{{ isNewUser ? 'Passwort anlegen' : 'Passwort ändern' }}</label>
        <input class="form-control" type="text" id="password" v-model="person.password" :placeholder="isNewUser ? 'Passwort anlegen' : 'Passwort ändern'" />
      </div>
    </div>
  </div>
  <h3>Bild</h3>
  <div wrap class="row form-all bild-box-outer">
    <div class="col-12">
      <input ref="file" type="file" @change="onChange" />
    </div>
    <div class="col-12">
      <img :src="avatarchanged" v-if="avatarchanged != ''" class="avatar" />
      <img :src="avatar" v-if="avatarchanged == '' && avatar.slice(-1) != '/'" class="avatar" />
    </div>
  </div>
  <div wrap class="row form-all">
    <div class="col-12 btn-box">
      <button class="btn-form" @click="onSaveClicked">Speichern</button>
      <button class="btn-form red" v-if="!isNewUser" @click="onDelete">User Löschen</button>
      <br /><span v-if="saving">Speichere... bitte warten</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, toRefs, inject, defineComponent, ref } from 'vue';

import { useStore } from 'vuex';

export default defineComponent({
  props: {
    person: {
      type: Object,
      required: true,
    },
    new: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const { person } = toRefs(props);
    const teams = ref([]);
    const locations = ref([]);
    const avatarchanged = ref('');
    const avatar = computed(() => store.getters['getAvatarUrl'](person.value.avatar));

    store.dispatch('getTeams').then((fetchedteams) => (teams.value = fetchedteams));
    store.dispatch('getLocations').then((fetchedlocations) => (locations.value = fetchedlocations));

    const onSave = inject('onSave') as Function;
    const onDelete = inject('onDelete');

    const onChange = (val: any) => {
      const value = val.target.files[0];
      person.value.avatar = value;
      avatarchanged.value = URL.createObjectURL(value);
    };

    const roles = [
      { value: 10, label: 'Mitarbeiter (10)' },
      { value: 20, label: 'Teamleiter (20)' },
      { value: 30, label: 'Management (30)' },
      { value: 50, label: 'Administrator (50)' },
    ];

    return {
      onSaveClicked: () => {
        onSave(person);
      },
      roles,
      avatarchanged,
      onChange,
      person,
      avatar,
      teams,
      locations, //computed(() => store.dispatch('getLocations')),
      isNewUser: props.new,
      onDelete,
    };
  },
});
</script>
