
import { computed, toRefs, inject, defineComponent, ref } from 'vue';

import { useStore } from 'vuex';

export default defineComponent({
  props: {
    person: {
      type: Object,
      required: true,
    },
    new: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const { person } = toRefs(props);
    const teams = ref([]);
    const locations = ref([]);
    const avatarchanged = ref('');
    const avatar = computed(() => store.getters['getAvatarUrl'](person.value.avatar));

    store.dispatch('getTeams').then((fetchedteams) => (teams.value = fetchedteams));
    store.dispatch('getLocations').then((fetchedlocations) => (locations.value = fetchedlocations));

    const onSave = inject('onSave') as Function;
    const onDelete = inject('onDelete');

    const onChange = (val: any) => {
      const value = val.target.files[0];
      person.value.avatar = value;
      avatarchanged.value = URL.createObjectURL(value);
    };

    const roles = [
      { value: 10, label: 'Mitarbeiter (10)' },
      { value: 20, label: 'Teamleiter (20)' },
      { value: 30, label: 'Management (30)' },
      { value: 50, label: 'Administrator (50)' },
    ];

    return {
      onSaveClicked: () => {
        onSave(person);
      },
      roles,
      avatarchanged,
      onChange,
      person,
      avatar,
      teams,
      locations, //computed(() => store.dispatch('getLocations')),
      isNewUser: props.new,
      onDelete,
    };
  },
});
